<template>
  <v-app id="inpsire">
    <v-content>
      <v-container fill-height fluid>
        <v-layout align-center justify-center>
          <v-card>
            <v-toolbar color="blue-grey darken-3 " dark flat>
              <v-toolbar-side-icon v-if="permissions.length">
                <router-link :to="{ name: 'Dashboard' }">
                  <v-icon color="white">home</v-icon>
                </router-link>
              </v-toolbar-side-icon>
              <v-toolbar-title>
                <h4>Kein Zugriff!</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <p>
                Sie haben sich als Nutzer für InGewA angemeldet. Für die
                Einrichtung der erforderlichen Rechte wenden Sie sich bitte an:
                <a href="mailto:steuern@enviaM.de">steuern@enviaM.de</a>.
              </p>
              <p>
                Weitere Informationen zum InGewA Gewerbesteuerassistenten finden
                Sie unter
                <a href="https://www.ingewa.com/" target="_blank"
                  >https://www.ingewa.com/</a
                >
                sowie unter
                <a href="https://youtu.be/SCyyYvH8zpg" target="_blank"
                  >https://youtu.be/SCyyYvH8zpg</a
                >
              </p>
            </v-card-text>
            <v-card-title>
              <h2>Partner</h2>
            </v-card-title>
            <v-layout class="mb-5" row wrap>
              <v-flex v-for="img in images" :key="img.name" md4>
                <a
                  :href="img.link"
                  class="d-flex justify-center"
                  target="_blank"
                >
                  <img
                    :alt="img.name"
                    :src="require(`@/assets/${img.name}.png`)"
                    class="my-3"
                    style="max-width: 75%"
                  />
                </a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'Info',
  data: () => ({
    images: [
      { name: 'Logo-neu', link: 'https://deepreader.de/' },
      { name: 'MFLOGO', link: 'https://mediaformat.de/' },
      { name: 'InGewA_Logo', link: 'https://www.ingewa.com/' },
    ],
  }),
  computed: {
    permissions() {
      return this.$store.getters.permissions
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.permissions.length) {
      next()
    }
  },
}
</script>

<style scoped></style>
